import request from '@/utils/request';

// 产品分类
export function goodsClassificationList(params) {
  return request({ url: `/goods_categories/`, method: 'get', params })
}

export function goodsClassificationCreate(data) {
  return request({ url: `/goods_categories/`, method: 'post', data })
}

export function goodsClassificationUpdate(data) {
  return request({ url: `/goods_categories/${data.id}/`, method: 'put', data })
}

export function goodsClassificationDestroy(data) {
  return request({ url: `/goods_categories/${data.id}/`, method: 'delete', data })
}

// 产品信息
export function goodsInformationList(params) {
  return request({ url: `/goods/`, method: 'get', params })
}

// 获取单个单品的信息
export function goodsInformationRetrieve(data) {
  return request({ url: `/goods/${data.id}/`, method: 'get', data })
}

export function goodsInformationCreate(data) {
  return request({ url: `/goods/`, method: 'post', data })
}

export function goodsInformationUpdate(data) {
  return request({ url: `/goods/${data.id}/`, method: 'put', data })
}

export function goodsInformationDestroy(data) {
  return request({ url: `/goods/${data.id}/`, method: 'delete', data })
}

// 产品单位
export function goodsUnitList(params) {
  return request({ url: `/goods_units/`, method: 'get', params })
}

export function goodsUnitCreate(data) {
  return request({ url: `/goods_units/`, method: 'post', data })
}

export function goodsUnitUpdate(data) {
  return request({ url: `/goods_units/${data.id}/`, method: 'put', data })
}

export function goodsUnitDestroy(data) {
  return request({ url: `/goods_units/${data.id}/`, method: 'delete', data })
}

// 获取cs的价格
export function goodsCsPrice(params) {
  return request({ url: `/goods/cs_price/`, method: 'get', params })
}

// 获取asin信息
export function asinInfo(params) {
  return request({ url: `/goods/asin_info/`, method: 'get', params })
}


//产品开发
export function developGoodsList(params){
  return request({ url: `/devel/`, method: 'get', params })
}
//产品开发详情
export function developGoodsDetail(params) {
  return request({ url: `/devel/${params.id}/`, method: 'get' })
}

//产品开发详情  在同事发起两个请求的时候patch会失效
export function developGoodsUpdate_(data) {
  return request({ url: `/devel/${data.id}/`, method: 'patch', data})
}

//产品开发详情
export function developGoodsUpdate(data) {
  return request({ url: `/devel/${data.id}/`, method: 'put', data})
}